/*----- HEADER -----*/
.mobile-menu-icon i{display: none ;}
#header {
    background: #ffffff;
    padding: 30px 0;
}
.header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 15px;
    align-items: center;
}
.header .menu ul {
    display: flex;
    margin: 0;
    padding: 0;
    align-items: center;
}
.header .menu ul li {
    padding: 0 22px;
}
.header .menu ul li a {
    font-size: 16px;
    color: #000;
    font-family: 'Metropolis Medium';
    position: relative;
    display: inline-block;
}
.header .menu ul li.start-free {
    padding-right: 0;
}
.header .menu ul li.signin {
    padding-right: 0;
}
.header .menu ul li.start-free a {
    color: #fff;
    background: linear-gradient(108.43deg, #5832CD 0.12%, #AC0487 50.83%, #5832CD 99.46%);
    box-shadow: 0px 0px 4px rgba(143, 209, 30, 0.3);
    line-height: 35px;
    display: inline-block;
    padding: 0 25px;
    border-radius: 50px;
    font-size: 15px;
    font-family: 'Metropolis Regular';
}
.header .menu ul li.signin a {
    background: linear-gradient(90deg, #70BE2A 0%, #C2D429 100%);
    box-shadow: 0px 0px 4px rgba(143, 209, 30, 0.3);
    border-radius: 50px;
    color: #fff;
    padding: 0 25px;
    line-height: 35px;
    display: inline-block;
    font-size: 15px;
}
.header .menu ul li a:before {
    content: '';
    width: 18px;
    height: 8px;
    background: url('../images/menu-icon.png') no-repeat;
    position: absolute;
    bottom: -20px;
    left: -25px;
    transition: left 0.5s;
    transform: translateX(-50%);
    opacity: 0;
}
.header .menu ul li a:hover:before, .header .menu ul li a.active:before {
    left: 50%;
    opacity: 1;
}
#footer {
    background: #252625;
}
.footer-top {
    padding: 75px 0 0;
}
.footer-bottom {
    padding: 50px 0 20px;
    width: 100%;
}
.footer-bottom ul.f-menu {
    display: flex;
    justify-content: flex-end;
    margin: 0;
}
.footer-bottom ul.f-menu li {
    padding-left: 30px;
}
.footer-bottom ul.f-menu li a {
    color: #fff;
    opacity: 0.5;
    font-size: 13px;
    font-family: 'Metropolis Medium';
    letter-spacing: 0.5px;
}
.footer-bottom ul.f-menu li a:hover {
    background: linear-gradient(to right, #70BE2A, #C2D429);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    opacity: 1;
}
.footer-top span {
    margin-bottom: 30px;
    display: block;
}
.footer-top p {
    margin: 0;
    color: #fff;
    font-size: 16px;
    line-height: 22px;
    opacity: 0.9;
    max-width: 62%;
    letter-spacing: 0.5px;
}
.footer-top h4 {
    color: #fff;
    opacity: 0.9;
    font-size: 18px;
    font-family: 'Metropolis Semi Bold';
    font-weight: normal;
    letter-spacing: 0.5px;
    position: relative;
    margin-bottom: 30px;
}
.footer-top h4:before {content: '';position: absolute;left: 0;bottom: -5px;width: 50px;height: 1px;background: linear-gradient(
90deg
, #70BE2A 0%, #C2D429 100%);opacity: 0.7;}
.footer-top .f-services ul {
    margin: 0;
}
.footer-top .f-services ul li {margin-bottom: 5px;}
.footer-top .f-services ul li:last-child {
    margin-bottom: 0;
}
.footer-top .f-services ul li a {
    font-size: 15px;
    opacity: 0.9;
    color: #fff;
    line-height: 22px;
    letter-spacing: 0.5px;
}
.footer-top .f-services ul li a:hover {
    background: linear-gradient(to right, #70BE2A, #C2D429);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    opacity: 1;
    padding-left: 5px;
}
.footer-top .f-contact p {
    font-size: 14px;
    max-width: 100%;
}
.footer-top .f-contact h5 {
    color: #fff;
    opacity: 0.9;
    font-size: 18px;
    margin: 20px 0 20px 0;
}
.footer-top .social {
    margin: 0;
    display: flex;
}
.footer-top .social li {
    margin-right: 20px;
}
.footer-top .social li:last-child {
    margin-right: 0;
}
.footer-top .social li a {
    font-size: 24px;
    color: #fff;
    opacity: 0.6;
}
.footer-top .social li a:hover {
    background: linear-gradient(to right, #70BE2A, #C2D429);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    opacity: 1;
}
/*----- FOOTER -----*/