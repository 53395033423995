/*----- FONTS -----*/
/* files */
@import url('../css/header_footer.css');
@import url('../css/fonts.css');
/*@import url('../css/responsive.css');*/
/*----- BASIC -----*/
html, body{
  height: auto;
}
body {
  font-family: 'Metropolis Regular';
  font-weight: 400;
  background: #fff;
  color: #000;
  line-height: 1.5;
  font-size: 16px;
  position: relative;
  overflow-x: hidden;
  transition: all ease 0.5s;
  letter-spacing: 0.5px;
}
a, a:hover{
  color: #fff;
  -webkit-transition: ease-in-out 0.5s;
  -moz-transition: ease-in-out 0.5s;
  -o-transition: ease-in-out 0.5s;
  transition: ease-in-out 0.5s;
  text-decoration: none;
}
ul{
  list-style: none;
}
h1,h2,h3{
  font-family: 'Metropolis Bold';
}
h4,h5,h6{
  font-family: 'Metropolis Medium';
}
.favicon{
  width: 16px;
  height: 16px;
  object-fit: contain;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
input, select, textarea, button {
  box-shadow: none;
  outline: none;
  background: none;
}
body::-webkit-scrollbar-track
{
  background-color: #C4C4C4;
  border-radius: 0px;
}

body::-webkit-scrollbar
{
  width: 2px;
  background-color: #F5F5F5;
}

body::-webkit-scrollbar-thumb
{
  border-radius: 0px;
  background: transparent;
}


/*----- MAIN -----*/
.ds-head .wd-btn-box-log a:first-child{
  display: block!important;
}
#liberate-cont {
  background: url('../images/home-bg.jpg') no-repeat center center;
  background-size: 100% auto;
  height: 100vh;
  display: flex;
  align-items: center;
}
.libe-content h1 {
  color: #fff;
  font-size: 60px;
  margin: 0;
}
.libe-content p {
  margin: 20px 0 25px 0;
  color: #fff;
  font-size: 16px;
  line-height: 30px;
}
.libe-content a {
  font-size: 15px;
  border: solid 2px #fff;
  border-radius: 50px;
  padding: 0 30px;
  line-height: 31px;
  display: inline-block;
  font-family: 'Metropolis Medium';
}
.libe-content a:hover {
  background: #fff;
  color: #000;
}
.libe-content span {
  display: block;
  margin: 15px 0 0 0;
  font-size: 12px;
  color: #fff;
}
/* Contact us start */
.inner-baner {
  position: relative;  
}
.inner-baner .contact-banner-img {
  display: block;
  width: 100%;
}
.banner-cont-main {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}
.inner-baner-cont h1 {
  color: #fff;
  margin: 0 0 10px 0;
  font-size: 38px;
  font-family: 'Metropolis Extra Bold';
}
.inner-baner-cont p {
  font-size: 18px;
  color: #fff;
  margin: 0;
}
.contact-content-sect {
  padding: 75px 0;
}
.contact-main-cont {
  position: relative;
  width: 100%;
}
.contact-map {
  width: 100%;
}
.contact-map iframe {
  display: block;
  width: 100%;
  height: 650px;
}
.contact-form {
  position: absolute;
  top: 50px;
  left: 0;
  width: 550px;
  background: #FFFFFF;
  box-shadow: 2px 3px 21px rgba(0, 0, 0, 0.08);
  border-radius: 0px 13px 13px 0px;
  padding: 30px 40px;
}
.contact-form span {
  font-size: 16px;
  text-transform: uppercase;
  font-family: 'Metropolis Medium';
  background: linear-gradient(to right, #70BE2A, #C2D429);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.contact-form h2 {
  font-size: 36px;
  text-transform: uppercase;
  color: #000;
  margin: 15px 0 40px 0;
}
.c-form .form-group {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.c-form .form-group .form-box {
  position: relative;
  width: 48%;
}
.c-form .form-group .form-box input, .c-form .form-group .form-box textarea {
  height: 45px;
  border-radius: 6px;
  border: solid 1px #E2E2E2;
  padding: 0 40px 0 10px;
  font-size: 14px;
  color: #000;
  font-family: 'Metropolis Medium';
  letter-spacing: 0.5px;
  width: 100%;
  display: block;
  background: #F3F3F3;
}
.c-form .form-group .form-box i {
  position: absolute;
  right: 10px;
  top: 15px;
  font-size: 16px;
  color: #979797;
}
.form-group.massage .form-box {
  width: 100%;
}
.c-form .form-group .form-box textarea {
  resize: none;
  padding: 10px 40px 10px 10px;
  height: 140px;
}
.green-btn {
  color: #fff;
  background: linear-gradient(90deg, #70BE2A 0%, #C2D429 100%);
  box-shadow: 0px 0px 4px rgba(143, 209, 30, 0.3);
  border-radius: 42px;
  border: 0;
  font-size: 15px;
  text-transform: uppercase;
  font-family: 'Metropolis Medium';
  padding: 10px 40px;
}
.c-form .form-group:last-child {
  margin-bottom: 0;
}
.c-form .form-group .form-box input:focus, .c-form .form-group .form-box textarea:focus {
  background: #fff;
  border-image-source: linear-gradient(to left, #C2D429, #70BE2A);
  border-image-slice: 1;
}
/* Faq start */
.faq-content {
    margin-top: 75px;
    width: 100%;
}
.cont-title {
    margin-bottom: 40px;
}
.cont-title span {
    font-size: 14px;
    text-transform: uppercase;
    font-family: 'Metropolis Regular';
}
.cont-title h2 {
    font-size: 30px;
    text-transform: uppercase;
    margin: 10px 0 0 0;
}
.faq-content .card {
    background: #FFFFFF;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.08);
    border: 0;
    margin-bottom: 15px;
    border-radius: 16px;
}
.faq-content .card:last-child {
    margin-bottom: 0;
}
.faq-content .card .card-header {
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 16px;
    border: 0;
    padding: 0;
}
.faq-content .card .card-header h5 {
  width: 100%;
}
.faq-content .card .card-header .btn {
    font-size: 17px;
    color: #000;
    font-family: 'Metropolis Regular';
    text-decoration: none;
    outline: none;
    box-shadow: none;
    padding: 0 0 0 25px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 0;
}
.faq-content .card .card-header i {
    font-size: 24px;
    line-height: 75px;
    width: 75px;
    height: 75px;
    text-align: center;
    border-radius: 16px;
    background: linear-gradient(24.11deg, #70BE2A -28.78%, #C2D429 97.04%);
    color: #fff;
}
.faq-content .card .card-header .btn.collapsed i:before {
  content: '\f067';
}
.faq-content .card .card-body {font-size: 15px;font-family: 'Metropolis Regular';opacity: 0.7;border-top: solid 1px #ccc;padding: 30px 25px;}
.faq-contact .contact-form {
    position: static;
    border-radius: 13px;
}
.contact-content-sect.faq-contact {
    padding-bottom: 30px;
}
.faq-contact .contact-main-cont {
    display: flex;
    justify-content: space-between;
}
.faq-image {
    position: relative;
    bottom: -30px;
}
/* Pricing plan start */
.pricing-content-sect {
    padding: 75px 0;
}
.pricing-title {
    text-align: center;
}
.pricing-main-cont {
    width: 100%;
}
.pricing-title h2 {
    font-size: 36px;
    margin-bottom: 10px;
}
.pricing-title p {
    margin: 0;
    font-size: 16px;
    color: #000;
    opacity: 0.7;
    line-height: 22px;
}
.pricing-card-main {
    margin-top: 50px;
    display: flex;
}
.pricing-card {
    background: #FFFFFF;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
    border-radius: 22px;
    text-align: center;
}
.pricing-card .card-head {background: #F6F7F9;border-radius: 18px 18px 4px 4px;padding: 30px 0 70px 0;}
.pricing-card .card-head h4 {
    font-size: 26px;
    font-family: 'Metropolis Bold';
    line-height: normal;
    margin-bottom: 5px;
}
.pricing-card .card-head p {
    font-size: 16px;
    margin: 0;
    opacity: 0.5;
}
.pricing-icon {
    background: #FFFFFF;
    box-shadow: 0px 2px 11px rgba(0, 0, 0, 0.05);
    width: 90px;
    height: 90px;
    /*border: solid 2px #fff;*/
    border-radius: 50%;
    text-align: center;
    line-height: 86px;
    margin: -45px auto 0;
    position: relative;
}
.card-cont {
    padding-bottom: 40px;
}
.card-cont h3 {
    font-family: 'Metropolis Medium';
    font-size: 40px;
    margin: 20px 0;
}
.card-cont .green-btn {
    display: inline-block;
    background: #AEAEAE;
    box-shadow: 0px 0px 4px rgba(174, 174, 174, 0.3);
    border-radius: 42px;
    line-height: 42px;
    padding: 0 25px;
    font-size: 15px;
    text-transform: capitalize;
    transition: inherit;
}
.card-cont ul {
    margin: 0 0 25px 0;
    display: inline-block;
}
.card-cont ul li {
    text-align: left;
    font-size: 15px;
    align-items: center;
    display: flex;
    margin: 5px 0;
}
.card-cont ul li img {
    margin-right: 10px;
}
.card-cont h3 sub {opacity: 0.6;font-size: 16px;font-family: 'Metropolis Bold';}
.card-cont h3 span {
    font-size: 16px;
    font-family: 'Metropolis Regular';
    opacity: 0.6;
}
.pricing-card.moderate {
    margin-top: 50px;
}
.pricing-card:hover .green-btn {
    background: linear-gradient( 
90deg
 , #70BE2A 0%, #C2D429 100%);
    box-shadow: 0px 0px 4px rgb(143 209 30 / 30%);
    padding: 0 40px;
}
.pricing-card .pricing-icon:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50px;
    padding: 2px;
    background: linear-gradient(45deg,#fff,#fff);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, 
     linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
}
.pricing-card:hover {
  background: #FFFFFF;
  box-shadow: 0px 5px 10px rgba(143, 209, 30, 0.2);
}
.pricing-card:hover .pricing-icon:before {
    background: linear-gradient(45deg, #70BE2A, #C2D429);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, 
     linear-gradient(#fff 0 0);
}
.pricing-icon img {
    display: none;
}
.pricing-card:hover .pricing-icon img {
  display: inline-block;
}
.pricing-card:hover .pricing-icon svg {
  display: none;
}
.pricing-card.moderate .card-head {
  background: url('../images/moderate.png') no-repeat center center;
  background-size: cover;
}
.pricing-card.moderate .card-head h4 {
  color: #fff;
}
.pricing-card.moderate .card-head p {
  color: #fff;
  opacity: 0.9;
}
/* login start */
.signin-page {
    height: 100vh;
    width: 100%;
    background: url(../images/login-bg.png) no-repeat center center;
    background-size: cover;
}
.signin-cont-main {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
.signin-blog {
    max-width: 400px;
    text-align: center;
    padding: 30px 0;
}
.sign-box {
    background: #fff;
    box-shadow: 0px 4px 37px rgb(0 0 0 / 25%);
    border-radius: 21px;
    padding: 30px 40px;
    margin-top: 25px;
    position: relative;
}
.sign-box:before {content: '';width: 100%;height: 20px;position: absolute;bottom: -5px;left: 0;background: radial-gradient(50% 50% at 50% 50%, #D3F212 0%, rgba(211, 242, 18, 0) 100%);opacity: 0.3;}
.sign-box h1 {
    font-size: 24px;
    color: #2A6F12;
    margin: 0 0 10px 0;
    text-transform: uppercase;
    line-height: normal;
}
.sign-box p {
    font-size: 14px;
    line-height: 23px;
    color: #545454;
    margin: 0;
}
.sign-box p.dont {
    font-size: 15px;
    font-family: 'Metropolis Medium';
}
.sign-box p.dont a {
    color: #16BDC0;
}
.sign-box p.dont a:hover {
    color: #000;
}
.sign-box p.dont a i {
    margin-left: 10px;
}
.sign-box .forgot {
    font-size: 13px;
    font-family: 'Metropolis Medium';
    color: #16BDC0;
}
.sign-box .forgot:hover {
    color: #000;
}
.login-form {
    margin: 30px 0 10px 0;
}
.login-form .form-row {
    margin: 0 0 20px 0;
    width: 100%;
    position: relative;
}
.login-form .form-row input {
    height: 52px;
    padding: 0 40px 0 15px;
    width: 100%;
    border: 0;
    background: #F5F5F5;
    border-radius: 11px;
    font-size: 15px;
    font-family: 'Metropolis Medium';
    color: #545454;
}
.login-form .form-row i {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 18px;
    color: #c3c3c3;
}
.login-form .form-row button {
    background: linear-gradient(90deg, #FFCC21 0%, #FFBE22 100%);
    border-radius: 8px;
    border: 0;
    width: 100%;
    height: 52px;
    font-size: 16px;
    text-transform: uppercase;
    color: #fff;
    font-family: 'Metropolis Bold';
    filter: drop-shadow(0px 9px 30px rgba(93, 181, 48, 0.4));
}
.social a {
    color: #545454;
    font-size: 15px;
    margin-right: 30px;
    text-align: center;
}
.social a.facebook i {
    font-size: 24px;
    background: linear-gradient(180deg, #18ACFE 0%, #0163E0 99.7%);
    border-radius: 50%;
    width: 25px;
    height: 25px;
    line-height: 25px;
    text-align: center;
}
.social a.google i {
    width: 25px;
    height: 25px;
    background: #FFFFFF;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12);
    font-size: 18px;
    border-radius: 50px;
    line-height: 25px;
}
.login-form .form-row.action {
    margin-bottom: 30px;
}
.social {
    margin: 20px 0 25px 0;
}
.social a.facebook span, .social a.google span {background: linear-gradient(180deg, #18ACFE 0%, #0163E0 99.7%);width: 25px;height: 25px;line-height: 25px;display: inline-block;border-radius: 50px;}
.social a.google span {
    background: #FFFFFF;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12);
}
/* dashboard start */

/* sidebar start */
.dashboard {
    background: #E7F5F3;
}
.ds-leftbar-main {
    background: linear-gradient(180deg, #2C575C 5.22%, #65A3A9 100%);
    box-shadow: inset -3px 0px 13px rgba(0, 0, 0, 0.1);
    border-radius: 0px 30px 30px 0px;
    width: 220px;
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
}
.leftbar {
    margin-top: 70px;
    text-align: center;
}
.leftbar a img {
    max-width: 135px;
}
.leftbar .side-menu {
    text-align: left;
    margin: 50px 0 0 0;
    padding-left: 25px;
}
.side-menu ul {
    margin: 0;
}
.side-menu ul li {
    margin-bottom: 10px;
    position: relative;
}
.side-menu ul li:last-child {
    margin: 0;
}
.side-menu ul li a {
    display: block;
    line-height: 35px;
    padding: 5px 25px;
    color: #F5F5F5;
    font-size: 16px;
    font-family: 'Metropolis Medium';
    position: relative;
}
.side-menu ul li a.active:before, .side-menu ul li a:hover:before, .side-menu ul li a.active:after, .side-menu ul li a:hover:after {
  content: '';
  position: absolute;
  right: 0;
  top: -18px;
  background: url('../images/active-vector.png') no-repeat;
  width: 18px;
  height:18px;
}
.side-menu ul li a.active:after, .side-menu ul li a:hover:after {
  top: auto;
  bottom: -18px;
  transform: rotate(-90deg);
}
.side-menu ul li a svg {
    margin-right: 10px;
    width: 22px;
    height: 22px;
    object-fit: contain;
}
.side-menu ul li .entries-count {
    position: absolute;
    right: 25px;
    top: 8px;
    background: #FFD201;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    text-align: center;
    line-height: 20px;
    font-size: 11px;
    font-family: 'Metropolis Medium';
    color: #009146;
}
.side-menu ul li a svg path {
    fill: #F5F5F5;
}
.side-menu ul li a.active, .side-menu ul li a:hover {
    background: #E7F5F3;
    border-radius: 50px 0 0 50px;
    color: #2F5B60;
}
.side-menu ul li a.active svg path, .side-menu ul li a:hover svg path {
  fill: #2F5B60;
}
.side-menu ul li a.active svg.stroke-svg path, .side-menu ul li a:hover svg.stroke-svg path {
  stroke: #2F5B60;
}
.ds-rightbar {
    margin-left: 250px;
}
.ds-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(0deg, #FFFFFF, #FFFFFF);
    border-radius: 0px 0px 0px 20px;
    padding: 20px 30px;
}
.ds-head h1 {
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    font-size: 24px;
    color: #3E5E5F;
}
.ds-head .btn-box a {
    color: #545454;
    font-size: 16px;
    font-family: 'Metropolis Semi Bold';
}
.ds-head .btn-box a.upg-btn {background: linear-gradient(90deg, #DD6960 0%, #E99089 100%);border-radius: 90px;color: #fff;font-size: 16px;line-height: 42px;display: inline-block;padding: 0 40px;margin-left: 25px;}
.ds-head .btn-box a.upg-btn:hover {background: linear-gradient(
180deg
, #2C575C 5.22%, #65A3A9 100%);}
.ds-midd-section {
    padding: 40px 30px 40px 0;
    display: flex;
}
.ds-midd-section .midd-top {
    border-bottom: solid 1px #cacaca;
    margin-bottom: 40px;
    padding-bottom: 15px;
}
.ds-midd-section .midd-top h2 {
    font-size: 22px;
    margin-bottom: 0px;
    color: #0E1C30;
}
.Weekly-blog .Weekly-box span svg {
    width: 30px;
    height: 30px;
    object-fit: contain;
    margin-bottom: 10px;
}
.recom-img {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    background:#e7f5f3;
    position: absolute;
    overflow: hidden;
    left: -30px;
    top: -20px;
}
.recom-img img {
    width: 100%;
    transition: all ease 1s;
}
.recom-img:hover img {
    transform: scale(1.2);
    border-radius: 50%;
}
.recom-box-main {
    display: flex;
    padding-left: 30px;
}
.recommended-blog {
    display: flex;
}
.recom-box-main:nth-child(2n) {
    margin-left: 30px;
}
.recommended-blog .recom-cont {
    background: #2E5B60;
    border-radius: 15px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 20px 20px 26px 80px;
    position: relative;
}
.recom-cont .recom-text h5 {
    font-size: 16px;
    color: #fff;
    line-height: 20px;
    font-family: 'Metropolis Medium';
    margin: 0 0 5px 0;
    text-transform: capitalize;
}
.recom-cont .recom-text p {
    font-size: 13px;
    color: #fff;
    opacity: 0.5;
    margin: 0 0 0px;
}
.weekly {
    padding-left: 30px;
    padding-right: 0;
}
.recommend {
    padding-left: 0;
    padding-right: 30px;
}
.weekly .midd-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
}
.weekly .midd-top select {
    padding: 10px 40px 8px 20px;
    height: fit-content;
    font-size: 14px;
    color: #326166;
    font-family: 'Metropolis Medium';
    border-radius: 50px;
    border: 0;
    appearance: none;
    line-height: normal;
    background-position: 90% 50%;
}
select {
    -moz-appearance: window;
    -webkit-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    background: #fff url(../images/form-dropdown.png) no-repeat 97% center;
}
.Weekly-blog {
    display: flex;
    margin: 55px 0 0;
}
.Weekly-blog .Weekly-box {
    width: 100%;
    margin-right: 15px;
    background: #FFFFFF;
    box-shadow: 0px 4px 14px rgb(0 0 0 / 15%);
    border-radius: 8px;
    text-align: center;
    padding: 0px 10px 5px;
}
.Weekly-blog .Weekly-box:last-child {
    margin-right: 0;
}
.Weekly-blog .Weekly-box strong {
    display: block;
    font-size: 34px;
    font-family: 'Metropolis Bold';
    line-height: normal;
    color: #2E5A5F;
    margin-top: 10px;
}
.Weekly-blog .Weekly-box p {
    margin: 0;
    font-size: 14px;
    color: #2E5A5F;
}
.Weekly-blog .Weekly-box strong sub {
    font-size: 20px;
    bottom: 0;
    font-family: 'Metropolis Medium';
}
.Weekly-blog .Weekly-box:hover span svg path{
  stroke: #2E5B60;
  fill: #2E5B60;
}
.ds-bottom-section .tab-pane.show {
    display: flex;
    flex-flow: column;
}
.tab-head {
    background: #fff;
    border-radius: 50px;
    display: flex;
    justify-content: space-between;
    padding: 7px;
}
.ds-bottom-section {
    padding-right: 30px;
    padding-bottom: 30px;
}
.tab-head .nav-tabs {
    border: 0;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}
.tab-head .nav-tabs li a {
    color: #666;
    font-size: 16px;
    font-family: 'Metropolis Medium';
    line-height: normal;
    padding: 13px 25px;
    display: inline-block;
}
.tab-head .nav-tabs li a.active, .tab-head .nav-tabs li a:hover {
    background: rgba(46, 91, 96, 0.95);
    border-radius: 90px;
    color: #fff;
}
.search-box {
    background: #f5f5f5;
    border-radius: 20px;
    height: 42px;
    width: 310px;
    position: relative;
}
.search-box input {
    font-size: 13px;
    height: 40px;
    width: 100%;
    border-radius: 20px;
    border: 0;
    padding: 0 50px 0 20px;
    color: #828282;
    font-family: 'Metropolis Medium';
}
.search-box button {
    width: 40px;
    height: 40px;
    border-radius: 50px;
    border: 0;
    background: #828282;
    color: #fff;
    font-size: 18px;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
}
.search-box button:hover {
    background: rgba(46, 91, 96, 0.95);
}
.ds-bottom-section .tab-content {
    margin-top: 30px;
}
.ds-bottom-section .tab-pane .tab-cont-box {
    position: relative;
    background-color: #FFFFFF;
    margin-bottom: 0.5rem;
    border-radius: 10px 10px 10px 10px;
    padding: 0 0 15px;
}
.ds-bottom-section .tab-pane .tab-cont-box a.wd-medi-lock{
    position: absolute;
    top: 14px;
    right: 14px;
}
.ds-bottom-section .tab-pane .tab-cont-box .meditation-img {
    display: block;
    width: 100%;
    position: relative;
    overflow: hidden;
    border-radius: 15px 15px 0 0;
}
.ds-bottom-section .tab-pane .tab-cont-box .meditation-img img {
    width: 100%;
    transition: all ease 0.5s;
    transform: scale(1);
    height: 330px;
    object-fit: cover;
}
.ds-bottom-section .tab-pane .tab-cont-box .meditation-img:hover img {transform: scale(1.2);}
.ds-bottom-section .tab-pane .tab-cont-box h6 {
    margin: 20px 0 10px 0;
    font-size: 15px;
    font-family: 'Metropolis Bold';
    color: #2E5B60;
    padding: 0 15px;
    text-align: center;
}
.ds-bottom-section .tab-pane .tab-cont-box p {
    line-height: normal;
    font-size: 13px;
    color: #000;
    opacity: 0.5;
    margin: 0;
    padding: 0 15px;
    text-align: center;
}
/* chapters list start */
.chapters-page {
  background: url('../images/chapters-bg.jpg') no-repeat;
  background-size: cover;
}
.chapters-page .ds-head a {
    color: #fff;
}
.chapters-page .ds-head {
    background: transparent;
    justify-content: flex-end;
}
.play-list-cont .back-home {
    border-bottom: solid 1px #aeaeae;
    padding: 15px 0;
    margin-bottom: 40px;
}
.play-list-cont .back-home a {
    font-size: 16px;
    font-family: 'Metropolis Semi Bold';
}
.play-list-cont .back-home a i {
    width: 30px;
    height: 30px;
    background: #fff;
    border-radius: 50px;
    text-align: center;
    color: #305D62;
    line-height: 30px;
    font-size: 16px;
    margin-right: 10px;
}
.play-list-cont .items-list {
    text-align: center;
}
.play-list-cont .items-list h1 {
    font-size: 36px;
    color: #fff;
    line-height: normal;
    margin: 0 0 10px 0;
}
.play-list-cont .items-list p {
    max-width: 43%;
    margin: 0 auto;
    color: #fff;
    font-size: 16px;
}
.play-list-cont .items-list .music-list {
    max-width: 550px;
    margin: 40px auto 0;
}
.play-list-cont .items-list .music-list li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
}
.play-list-cont .items-list .music-list li a {
    font-size: 26px;
    line-height: 30px;
}
.play-list-cont .items-list .music-list li:last-child {
    margin-bottom: 0;
}
.play-list-cont .items-list .music-list li .like {
    font-size: 24px;
    line-height: normal;
    vertical-align: bottom;
}
.play-list-cont {
    padding-bottom: 100px;
    padding-right: 30px;
}
.play-list-cont .items-list .music-list li a:hover {
    color: #de6b62;
}
.video-view .back-home {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.video-view .back-home h1 {
    font-size: 30px;
    line-height: normal;
    margin: 0;
    color: #fff;
}
.video-view .back-home .head-title {
    display: flex;
}
.video-view .back-home .colse-icon {
    position: absolute;
    right: 0;
    color: #fff;
    font-size: 30px;
    cursor: pointer;
}
.video-view .back-home .colse-icon:hover {
    color: #de6d64;
}
.video-view .back-home .head-title .favrate-icon i {
    background: transparent;
    width: auto;
    height: auto;
    margin-left: 20px;
    margin-right: 00;
    font-size: 24px;
    color: #fff;
}
.video-view .back-home .head-title .favrate-icon:hover i {
    color: #de6d64;
}
.video-blog {
    padding: 0 20px;
}
.next-prev-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.next-prev-row .prev-arrow, .next-prev-row .next-arrow {
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    border-radius: 50px;
    background: linear-gradient(180deg, #2C575C 5.22%, #65A3A9 100%);
    font-size: 24px;
    color: #fff;
    cursor: pointer;
}
.video-progress {
    width: 90%;
    display: flex;
}
.video-progress-line {
    width: calc(25% - 15px);
    height: 8px;
    background: #fff;
    margin-right: 15px;
    border-radius: 10px;
    position: relative;
}
.video-progress-line:last-child {
    margin-right: 0;
}
.video-progress-line.fill:before {content: '';width: 40%;height: 8px;position: absolute;left: 0;top: 0;border-radius: 10px;background: linear-gradient(180deg, #2C575C 5.22%, #65A3A9 100%);box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);}
.video-blog .video-box {
    padding: 0 40px;
    margin-top: 30px;
}
.video-blog .video-box video {
    width: 100%;
    height: 600px;
}
.video-view .play-list-cont {
    padding-bottom: 50px;
}
.audio-box {
    margin: 100px 0 0 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.audio-box .audio-progress {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    border: solid 6px #2E5B60;
    text-align: center;
    line-height: 188px;
    font-size: 90px;
    color: #fff;
    position: relative;
}
.audio-box .audio-progress:before {content: '';width: 200px;height: 200px;bottom: -6px;left: -6px;border-radius: 50%;position: absolute;border-left: solid 6px transparent;border-bottom: solid 6px #fff;border-right: solid 6px transparent;border-top: solid 6px transparent;}
.audio-box .time {
    color: #fff;
    font-size: 25px;
    font-family: 'Metropolis Semi Bold';
    margin: 40px 0 100px 0;
}
.audio-box .controler a {
    margin: 0 20px;
    line-height: 0;
}
.audio-box .controler a.play {
    font-size: 48px;
}
.audio-box .controler {
    display: flex;
    align-items: center;
}
.audio-box .controler a.voliume {
    font-size: 36px;
}
.dashboard.video-view {
  background-image: url('../images/video-bg.jpg');
}
.next-prev-row .prev-arrow i, .next-prev-row .next-arrow i {
    margin-top: 8px;
}
.chapter-box {
    background: #fff;
    width: 370px;
    margin: 100px auto 0;
    padding: 40px 15px 30px 15px;
    text-align: center;
    border-radius: 12px;
}
.chapter-box h3 {
    margin: 30px 0 20px 0;
    font-size: 20px;
    line-height: 24px;
    color: #386368;
}
.chapter-box p {
    font-size: 16px;
    color: #545454;
    margin: 0 0 15px 0;
}
.chapter-box a {
    font-family: 'Metropolis Medium';
    color: #fff;
    background: linear-gradient(180deg, #2C575C 5.22%, #65A3A9 100%);
    border-radius: 90px;
    display: inline-block;
    line-height: 42px;
    padding: 0 25px;
}
.chapter-box a:hover {
    background: linear-gradient(90deg, #DD6960 0%, #E99089 100%);
}
/* entries start */
.entries-page .ds-bottom-section {
    margin-top: 50px;
}
.entries-page .tab-head {
    background: none;
    align-items: center;
}
.entries-page .search-box {
    background: #fff;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.15);
    padding: 3px;
}
.entries-page .search-box input {
    height: 36px;
}
.entries-page .search-box button {
    height: 36px;
    width: 36px;
    top: 3px;
    right: 3px;
}
.etries-page .tab-head .nav- tabs {
    background: #fff;
}
.entries-page .tab-head .nav-tabs {
    background: #fff;
    border-radius: 50px;
    box-shadow: 0px -4px 19px rgba(0, 0, 0, 0.15);
    padding: 5px;
}
.entries-table {
    background: #fff;
    box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.15);
    border-radius: 30px;
    display: flex;
    width: 100%;
    padding: 15px 30px;
}
.entries-table .table-blog {width: 15%;}
.entries-table .table-row {
    border-bottom: solid 1px #CECECD;
    color: #000;
    opacity: 0.9;
    padding: 10px 15px 10px 0;
    font-size: 16px;
}
.entries-table .table-blog.question, .entries-table .table-blog.answer {
    width: 24%;
}
.entries-table .table-blog.edit {
    width: 10%;
    text-align: center;
}
.entries-table .table-blog.date {
    width: 12%;
}
.entries-table .table-row:last-child {
    border: 0;
}
.entries-table .table-row.title {
    opacity: 1;
    font-family: 'Metropolis Semi Bold';
    border-color: #9c9c9c;
}
.entries-table .table-row .edit-icon {
    color: #386368;
    font-size: 16px;
}
.entries-table .table-row .edit-icon:hover {
    color: #F47272;
}
.entries-table .table-row .delete-icon {
    color: #F47272;
    font-size: 16px;
}
.entries-table .table-row .delete-icon:hover {
    color: #386368;
}
.custom-entries .entries-table .table-blog.edit {
    width: 5%;
}
.custom-entries .entries-table .table-blog.delete {
    width: 5%;
    text-align: center;
}
.add-entries-btn {
    position: relative;
    margin: 0 0 0 15px;
    width: 150px;
    height: 42px;
    overflow: hidden;
    background: linear-gradient(90deg, #2C575C 3.99%, #65A3A9 100%);
    border-radius: 90px;
    padding: 5px;
}
.add-entries {
    display: flex;
    align-items: center;
}
.add-entries-btn input {
    font-size: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    border-radius: 50px;
    cursor: pointer;
}
.add-entries-btn a {
    font-size: 16px;
    font-family: 'Metropolis Medium';
    display: inline-block;
    line-height: 32px;
}
.add-entries-btn a i {
    background: #fff;
    color: #386368;
    border-radius: 50px;
    width: 32px;
    height: 32px;
    vertical-align: top;
    text-align: center;
    line-height: 32px;
    margin-right: 5px;
}
.add-modal-main, .edit-modal-main {
    position: fixed;
    z-index: 99999;
    background: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    padding: 50px 0;
}
.add-modal {
    margin: 0 auto;
    background: #FFFFFF;
    box-shadow: 0px 4px 37px rgba(0, 0, 0, 0.25);
    border-radius: 21px;
    max-width: 670px;
    position: absolute;
    left: 0;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
}
.add-modal .modal-head {
    background: #35666B;
    text-align: center;
    border-radius: 20px 20px 0 0;
    position: relative;
}
.add-modal .modal-head h1 {
    color: #fff;
    font-size: 24px;
    text-transform: uppercase;
    margin: 0;
    line-height: 70px;
}
.add-modal .modal-head .close-btn {
    position: absolute;
    right: -10px;
    top: -10px;
    color: #fff;
    font-size: 30px;
    width: 30px;
    height: 30px;
    cursor: pointer;
}
.add-modal .modal-head .close-btn i {
    vertical-align: top;
}
.add-modal .modal-head .close-btn:hover {
    color: #F47272;
}
.add-modal .modal-body {
    padding: 30px 15px;
}
.add-modal .modal-body .form-row {
    margin: 0 0 20px 0;
}
.add-modal .modal-body .form-row .form-box {
    width: 50%;
    padding: 0 15px;
}
.add-modal .modal-body .form-row .form-box label {
    font-size: 15px;
    color: #545454;
    margin: 0 0 10px 0;
    line-height: normal;
}
.add-modal .modal-body .form-row .form-box input, .add-modal .modal-body .form-row .form-box textarea {
    width: 100%;
    border: 0;
    border-radius: 10px;
    background: #F5F5F5;
    height: 42px;
    padding: 0 15px;
    color: #545454;
    font-size: 15px;
    font-family: 'Metropolis Medium';
}
.add-modal .modal-body .form-row .form-box textarea {
    resize: none;
    height: 120px;
    padding: 10px 15px;
}
.add-modal .modal-body .form-row.action {text-align: center;display: block;margin-bottom: 0;}
.add-modal .modal-body .form-row.action button {
    color: #fff;
    background: #8D8D8D;
    border: 0;
    border-radius: 50px;
    height: 42px;
    width: 135px;
    margin: 0 10px;
    font-family: 'Metropolis Medium';
    transition: all ease 0.5s;
}
.add-modal .modal-body .form-row.action button.save {
    background: #65A6AE;
}
.add-modal .modal-body .form-row.action button:hover {
    background: #35666b;
}
/* profile start */
.profile-cont-main {
    background: #fff;
    border-radius: 20px;
    padding: 20px 15px;
    margin-top: 30px;
}
.profile-detail a img {
    width: 120px;
    height: 120px;
}
.profile-detail .pro-text {
    padding-left: 30px;
}
.profile-detail .pro-text h2 {
    font-size: 24px;
    margin-bottom: 10px;
    line-height: normal;
}
.profile-detail .pro-text p {
    margin: 0;
    font-size: 16px;
    line-height: 24px;
    color: #000;
    opacity: 0.4;
}
.profile-btn {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.profile-btn a {
    background: linear-gradient(90deg, #2C575C 9.14%, #65A3A9 95.27%);
    border-radius: 90px;
    text-align: center;
    color: #fff;
    font-size: 16px;
    font-family: 'Metropolis Medium';
    line-height: 42px;
    width: 150px;
}
.profile-btn a i {
    margin-right: 10px;
}
.profile-btn a:hover {
    background: linear-gradient(90deg, #65A3A9 9.14%, #2C575C 95.27%);
}
.profile-btn a.logout-btn {
    margin-top: 20px;
    background: linear-gradient(90deg, #DD6960 0%, #E99089 100%);
    display: inline-block;
}
.profile-btn a.logout-btn:hover {
    background: linear-gradient(90deg, #E99089 0%, #DD6960 100%);
}
.profile-tab-cont {
    margin-top: 20px;
}
.profile-tab-cont .tab-head {
    border-radius: 0;
    padding: 0 15px;
    border-bottom: solid 1px #C4C4C4;
    justify-content: normal;
}
.profile-tab-cont .tab-head .nav-tabs li a {
    position: relative;
    line-height: normal;
    padding: 0 25px 10px;
    font-size: 20px;
    border-bottom: solid 3px transparent;
}
.profile-tab-cont .tab-head .nav-tabs li a.active, .profile-tab-cont .tab-head .nav-tabs li a:hover {
    background: transparent;
    color: #000;
    border-bottom: solid 3px #2e5b60f2;
    border-radius: 0;
}
.p-f-detail {
    padding: 50px 0;
}
.u-d-row {
    display: flex;
    margin: 0 0 30px 0;
}
.u-d-row .u-d-box {
    width: 50%;
    padding-right: 15px;
}
.u-d-row .u-d-box label {
    line-height: normal;
    margin: 0;
    font-size: 15px;
    color: #545454;
    opacity: 0.6;
}
.u-d-row .u-d-box p {
    font-size: 15px;
    color: #545454;
    opacity: 0.9;
    font-family: 'Metropolis Medium';
    margin: 5px 0 0 0;
}
.u-d-row:last-child {
    margin: 0;
}
.plan-box {max-width: 330px;text-align: center;}
.plan-row.current {
    background: #2C575C;
    border-radius: 35px 35px 0 0;
    padding: 30px 0;
}
.plan-row.expired {
    background: #65A3A9;
    border-radius: 0 0 35px 35px;
    padding: 20px 0;
}
.plan-row.current p, .plan-row.expired p {
    color: #fff;
    font-size: 22px;
    line-height: 22px;
    font-family: 'Metropolis Medium';
    margin-bottom: 15px;
}
.plan-row.current strong, .plan-row.expired strong {
    font-size: 22px;
    line-height: normal;
    color: #FFD201;
    font-family: 'Metropolis Semi Bold';
}
.plan-row.current strong sub {
    bottom: 0;
    font-size: 16px;
}
.lessons {padding-top: 10px;direction: rtl;}
.lessons .lessons-box {
    margin-top: 30px;
    max-width: 75%;
    background: linear-gradient(89.92deg, #2C575C 8.41%, #65A3A9 93.01%);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    display: flex;
    direction: ltr;
    align-items: center;
    padding: 20px 0;
}
.lessons-img {
    margin-left: -50px;
}
.lessons-text p {
    color: #fff;
    font-size: 20px;
    font-family: 'Metropolis Medium';
    margin: 0 0 10px 0;
    line-height: normal;
}
.lessons-text {
    padding: 0 30px;
}
.lessons-text strong {
    font-size: 70px;
    line-height: normal;
    color: #fff;
    font-family: 'Metropolis Medium';
    font-weight: normal;
}
.d-footer {
    background: #252625;
    border-radius: 20px 0px 0px 0px;
    padding: 20px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.f-social {
    display: flex;
}
.f-social p {
    margin: 0;
    color: #fff;
    font-size: 15px;
}
.d-footer ul {
    margin: 0;
    display: flex;
}
.d-footer ul li {
    margin-left: 15px;
}
.d-footer ul li a {
    font-size: 16px;
    color: #fff;
    opacity: 0.6;
}
.d-footer ul li a:hover {
    opacity: 1;
    color: #FFD201;
}
.f-menu ul li {
    margin-left: 30px;
}
.f-menu ul li a {color: #fff;opacity: 0.6;font-size: 13px;font-family: 'Metropolis Medium';}
.f-menu ul li a:hover, .f-menu ul li a.active{
  color: #FFD201;
  opacity: 1;
}
.edit-form .u-d-box:last-child {
    padding-left: 15px;
    padding-right: 0;
}
.edit-form .u-d-box input {background: #F5F5F5;border: 1px solid rgba(0, 0, 0, 0.1);border-radius: 50px;height: 42px;width: 100%;margin-top: 10px;padding: 0 15px;font-size: 15px;font-family: 'Metropolis Medium';}
.edit-form .u-d-row.action {
    direction: rtl;
}
.edit-form .u-d-row.action button {
    font-size: 16px;
    color: #fff;
    border: 0;
    background: linear-gradient(180deg, #2C575C 5.22%, #65A3A9 100%);
    border-radius: 90px;
    line-height: 42px;
    width: 150px;
}
.edit-form .u-d-row.action button:hover {
    background: linear-gradient(180deg, #65A3A9 5.22%, #2C575C 100%);
}
.p-f-table {
    border: solid 1px #EDEDED;
    display: flex;
}
.p-f-t-blog.delete {
    width: 15%;
    text-align: center;
}
.p-f-t-blog.purchase, .p-f-t-blog.expired {
    width: 30%;
}
.p-f-t-blog.plan {
    width: 40%;
}
.p-f-t-row {
    font-size: 16px;
    color: #545454;
    padding: 10px 10px;
    border-right: solid 1px #EDEDED;
}
.p-f-t-row.title {
    background: linear-gradient(180deg, #2C575C 5.22%, #65A3A9 100%);
    color: #fff;
    font-family: 'Metropolis Medium';
}
.p-f-t-blog.delete .p-f-t-row.title {
    height: 44px;
}
.p-f-t-blog.delete .delete-icon {
    color: #F47272;
    font-size: 16px;
}
.p-f-t-blog.delete .delete-icon:hover {
    color: #386368;
}


/*====================login-popup==================*/
#login-popup .wd-model-header{
    border: none;
}
#login-popup .wd-model-header{
    justify-content: center;
    position: relative;
    text-align: center;
}
#login-popup .wd-model-header h4{
  
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 24px;
    text-transform: uppercase;
    color: #2A6F12;
}
#login-popup .wd-model-header p{
  
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 23px;
    text-align: center;
    text-transform: capitalize;
    color: #000000;
    margin-bottom: 0;
}
#login-popup .modal-content{
    background: #FFFFFF;
    box-shadow: 0px 4px 37px rgba(0, 0, 0, 0.25);
    border-radius: 21px;
    width: 375px;
    margin: auto;
}
#login-popup .wd-model-header button.close{
    margin: 0;
    position: absolute;
    right: 14px;
    top: 0;
    opacity: unset;
}
#login-popup .wd-model-header button.close i{
    color: #545454;
    width: 15px;
    height: 15px;
}
#login-popup .wd-model-body{
    margin: 0 auto;
}
#login-popup .wd-model-body .form-group input{
    background: #F5F5F5;
    border-radius: 11px;
    width: 320px;
    border: none;
    height:52px;
    display: block;
        padding: 0.375rem 0.75rem;
    margin-bottom: 1rem;
}
#login-popup .wd-model-body .form-group input::placeholder{
    
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 15px;
    color: #545454;
    opacity: 0.6;
}
#login-popup .wd-model-body .wd-eye-psw{
    position: relative;
}
#login-popup .wd-model-body .wd-eye-psw input{
    padding-right: 41px;
}
#login-popup .wd-model-body .wd-eye-psw a i{
    position: absolute;
    top: 19px;
    right: 14px;
    color:#222C53;
    opacity: 0.2;
}
#login-popup .wd-model-body .form-group button.wd-btn-login-pop{
    background: linear-gradient(90deg, #FFCC21 0%, #FFBE22 100%);
    border-radius: 8px;
  
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 30px;
    text-transform: uppercase;
    color: #FFFFFF;
    border: none;
    width: 320px;
    padding: 7px 0;
    box-shadow: 0px 9px 30px rgb(255 191 34 / 40%);
}
#login-popup .wd-model-body a.wd-for-psw{
  
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 30px;
    color: #16BDC0;
    text-align: center;
    display: block;
}
#login-popup .wd-model-body p{
  
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 30px;
    color: #545454;
    text-align: center;
}
#login-popup .wd-model-body p a{
  
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 30px;
    color: #16BDC0;

}

/*====================login-popup==================*/
/* 2-11-2021 */
.wd-sl-cutomewidth{
  width: 30%;
  margin: 0 auto;
}
.wd-sl-cutomewidth .u-d-box {
    width: 100% !important;
}
.wd-sl-emailinfo span{
  background: linear-gradient(
90deg, #2E5B60 0%, #61A2A9 100%);
    width: 40px;
    height: 40px;
    display: block;
    border-radius: 50%;
    padding: 7px 11px;
}
.wd-sl-emailinfo{
  display: block;
}
.wd-sl-emailinfo h6{
  font-size: 13px;
  color: rgba(0, 0, 0, 0.7);
  opacity: 0.7;
  margin-bottom: 5px;
}
.wd-sl-emailinfo h5{
  font-size: 16px;
  color: #000;
  margin-bottom: 0;
}
.wd-sl-emailinfo {
    display: flex;
    border: 0.5px solid rgb(0 0 0 / 36%);
    padding: 20px;
    margin-bottom: 30px;
    border-radius: 16px;
    align-items: center;
}
#faq .card{
  background: #FFFFFF;
    box-shadow: 0px 4px 14px rgb(0 0 0 / 8%);
    border-radius: 16px;
    border: none;
    padding: 10px 15px;
    margin-bottom: 30px;
}
#faq .card .card-header{background: unset;border: none;box-shadow: unset;padding: 0;}
#faq .card .card-header span{
  background: linear-gradient(
90deg, #2C575C 7.52%, #65A3A9 94.93%);
    border-radius: 16px;
    width: 50px;
    height: 50px;
    padding: 12px;
    position: absolute;
    right: 0;
    top: 0;
}
#faq .card .card-header a{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px 5px;
}
#faq .card .card-header a.btn.btn-header-link.collapsed span img {
    transform: rotate(
45deg);
}
#faq .card .card-header a:focus{box-shadow: none;}
#faq .card .card-header span img {
    width: 18px;
    height: 18px;
}
#faq .card-body {
    padding: 15px 5px;
    color: rgb(0 0 0 / 60%);
    line-height: 1.8;
    font-size: 16px;
}
#faq {
    margin: 10px 10px;
}
.wd-sl-dashtabs .nav-tabs{
  justify-content: unset;
}
.wd-sl-recipsfilter input{
  background-color: #FFFFFF;
}
.wd-sl-recmain {
    margin: 30px 0 0;
}
.wd-sl-recipsfilter {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    margin: 25px 0 0;
}
.wd-sl-filtersrch{
  display: flex;
  align-items: center;
}
.tab-cont-box span{
  color: #386368;
  font-size: 13px;
}
.tab-cont-box svg{
  width: 15px;
  height: 15px;
}
.wd-sl-innerbox{
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.form-group {
  display: block;
  margin-bottom: 0px;
}
.form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}
.form-group label {
  font-size: 12px;
    color: #666666;
    position: relative;
  cursor: pointer;
  margin-bottom: 0;
}
.form-group label:after {
  content:'';
  -webkit-appearance: none;
  background-color: #FFFFFF;
  border: 1px solid #396368;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 9px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-left: 15px;
}
.form-group input:checked + label:before {
  content: '';
  display: block;
  position: absolute;
  top: 0px;
  right: 0px;
  width: 7px;
  z-index: 1;
  height: 13px;
  border: solid #396368;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  margin: 1px 7px;
}
.hidden-filter-menu ul{margin-bottom: 0;display: flex;
    align-items: center;}
.wd-sl-customecheck {
    display: flex;
    align-items: center;
}
.wd-sl-customecheck .form-group{
    margin-right: 15px;
    display: flex;
    align-items: center;
}
.wd-sl-customecheck .form-group:last-child{margin-right: 0;}
select.wd-sl-custform {
    padding: 10px 35px 8px 15px;
    height: fit-content;
    font-size: 12px;
    color: #326166;
    font-family: 'Metropolis Medium';
    border-radius: 50px;
    border: 0;
    appearance: none;
    line-height: normal;
    background-position: 90% 50%;
}
select.wd-sl-custform {
    -moz-appearance: window;
    -webkit-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    background: #fff url(../images/form-dropdown.png) no-repeat 90% center;
}
.hidden-filter-menu ul li span {
    font-size: 12px;
    color: #666666;
    margin-right: 15px;
}
.hidden-filter-menu ul li{
  margin-right: 20px;
}
.hidden-filter-menu ul li:last-child{margin-right: 0;}
.hidden-filter-menu {
    position: absolute;
    left: 0;
}
.backto{
  font-size: 18px;
  margin-bottom: 1.5rem;
  color: #386368;
  display: block;
}
.backto:hover{color:#386368;}
.backto svg{
  width: 18px;
  height: 18px;
}
.wd-sl-recdetailtitle h2{
  color: #386368 !important;
  font-size: 24px;
  margin-right: 40px;
}
.wd-sl-recdetailtitle .midd-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.wd-sl-recdetailleft {
    display: flex;
    align-items: center;
}
.wd-sl-recdetailleft span{
  margin-right: 18px;
  font-size: 12px;
  color: #666666;
}
.wd-sl-recdetailleft svg{
  margin-left: 5px;
}
.wd-sl-ingridiantcardmain{
  background: #FFFFFF;
    box-shadow: 10px 10px 40px rgb(0 0 0 / 15%);
    border-radius: 12px;
    padding: 18px;
    margin-bottom: 30px;
}
.wd-sl-ingridiantcard{
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #DDDDDD;
    padding-bottom: 8px;
}
.wd-sl-ingridiantcard h5 {
    font-size: 20px;
    color: #386368;
    margin-bottom: 0;
    font-family: 'Metropolis Semi Bold';
}
.wd-sl-ingridiantcard .number span{
  background-color: #F5F5F5;
    width: 30px;
    height: 30px;
    display: inline-block;
    border-radius: 50%;
    color: #2E5B60;
    font-size: 22px;
    cursor: pointer;
    padding: 0px 7px;
    text-align: center;
    line-height: 30px;
}
.wd-sl-ingridiantcard .number input{
  color: #386368;
  font-size: 16px;
  border: none;
  text-align: center;
  display: inline-block;
  width: 40px;
}
.wd-sl-ingridiantcard .number {
    display: flex;
    align-items: center;
}
.wd-sl-ingridiantcard .number h6{
  font-size: 16px;
  color: #386368;
  font-family: 'Metropolis Regular';
  margin-bottom: 0;
  margin-right: 15px;
}
.wd-sl-ingridiantcardmain li{
  color: #292929;
  font-size: 16px;
  margin-bottom: 10px;
  text-align: left;
}
.wd-sl-ingridiantcardmain ul{margin-bottom: 0;}
.wd-sl-ingridiantcardmain li:last-child{margin-bottom: 0;}
.ingrid-justify{
  justify-content: unset !important;
}
.wd-sl-ingridiantcardmain span{
  color: #545454;
  font-size: 14px;
}
.wd-sl-spancats li{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgb(84 84 84 / 10%);
  padding: 10px 0;
}
.wd-sl-spancats li:last-child{
  padding-bottom: 0;
}
.wd-sl-greenwoodbg{
  background: rgba(0, 0, 0, 0.1);
  border-radius: 20px;
}
.wd-sl-greenwoodbg h6{
  color: #386368;
  display: inline-block;
  border-bottom: 1px solid #386368;
}
.wd-sl-totalbox{
  background: linear-gradient(90deg, #2E5B60 0%, #5C99A0 100%);
  border-radius: 50px;
  padding: 10px 20px;
  display: inline-block;
}
.wd-sl-totalbox span{
  font-size: 14px;
  color: #FFFFFF;
  margin-bottom: 0;
  margin-right: 10px;
}
.wd-sl-totalbox svg{
  margin-right: 10px;
}
.wd-sl-greenwoodbg li{
  color: #386368;
  font-size: 14px;
  padding: 5px 0;
}
.wd-sl-greenwoodbg{
  padding: 20px;
}
.wd-sl-centerslide span {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}
.wd-sl-centerslide span svg{
  background-color: #67A9B0;
  border-radius: 50%;
}
.wd-sl-centerslide .position-relative img{object-fit: cover;border-radius: 13px;}

/* progressbar */
.wd-sl-progress{
  position: relative;
  text-align: center;
}
.wd-sl-progress .barOverflow{ 
  position: relative;
    overflow: hidden;
    width: 260px;
    height: 130px;
    margin: 0 auto -70px;
}
.wd-sl-progress .bar{
  position: absolute;
  top: 0; left: 0;
  width: 260px; height: 260px; 
  border-radius: 50%;
  box-sizing: border-box;
  border: 10px solid #E9E9E9;     
  border-bottom-color: #DD6960;  
  border-right-color: #DD6960;
}
.wd-sl-progress span, .wd-sl-progress strong {
    color: #2E5B60;
    font-size: 42px;
    font-family: 'Metropolis Regular';
}
.wd-sl-progress p{
  font-size: 20px;
  color: #000;
}
.wd-sl-progress p {
    font-size: 16px;
    color: rgb(0 0 0 / 50%);
    line-height: 0px;
}
.wd-sl-setgoals h6{
  color: #3E7277;
  font-size: 16px;
  margin-bottom: 0;
}
.wd-sl-setgoals {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 67%;
    margin: 25px auto 0 auto;
}
.wd-sl-ingridiantcardmain p.onlytext{
  font-size: 14px;
  color: #2E5B60;
  font-family: 'Metropolis Medium';
  margin: 25px 0 0;
}
.wd-sl-ingridiantcardmain{
  text-align: center;
}
.wd-sl-greenbg{
  background: #9FBFC2 !important;
  padding: 0;
}
.highcharts-grid-line{
  stroke: transparent;
}
#container .highcharts-axis.highcharts-color-0 text, #container .highcharts-axis.highcharts-color-1 text, #container .highcharts-color-0 text, #container button.highcharts-a11y-proxy-button.highcharts-no-tooltip, #container  text.highcharts-credits, #container g.highcharts-legend-item.highcharts-column-series.highcharts-color-0.highcharts-series-0, #container g.highcharts-exporting-group{
  display: none;
}
#container g.highcharts-axis-labels.highcharts-yaxis-labels text {
    display: none;
}
.wd-sl-setprogress{
  padding: 30px !important;
}
/* chart */
.highcharts-figure, .highcharts-data-table table {
  width: 100%;
  margin: 0em auto;
}
.highcharts-data-table table {
  border-collapse: collapse;
  border: 1px solid #EBEBEB;
  margin: 10px auto;
  text-align: center;
  width: 100%;
  max-width: 500px;
}
.highcharts-data-table caption {
  padding: 1em 0;
  font-size: 1.2em;
  color: #555;
}
.highcharts-data-table th {
  font-weight: 600;
  padding: 0.5em;
}
.highcharts-data-table td, .highcharts-data-table th, .highcharts-data-table caption {
  padding: 0.5em;
}
.highcharts-data-table thead tr, .highcharts-data-table tr:nth-child(even) {
  background: #f8f8f8;
}
.highcharts-data-table tr:hover {
  background: #f1f7ff;
}
.highcharts-color-0 {
  fill: #FFFFFF;
}
.highcharts-axis.highcharts-color-0 text {
  fill: #7cb5ec;
}
.highcharts-color-1 {
  fill: #90ed7d;
  stroke: #90ed7d;
}
.highcharts-axis.highcharts-color-1 .highcharts-axis-line {
  stroke: #90ed7d;
}
.highcharts-axis.highcharts-color-1 text {
  fill: #90ed7d;
}
.highcharts-background {
    fill: transparent !important;
}
.highcharts-yaxis .highcharts-axis-line {
  stroke-width: 0px;
}
.highcharts-axis-line{
  stroke: #FFFFFF;
}
.highcharts-axis-labels {
    fill: #fff;
    font-size: 13px;
    font-family: 'Metropolis Bold';
}
.highcharts-figure #container{
  height: 300px;
}
.wd-sl-transparent{
  background: rgb(0 0 0 / 5%)0;
  border-radius: 20px;
}
.wd-sl-topset{
  margin-bottom: 30px;
}
.wd-sl-goalstop {
  display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px 20px 12px;
    border-bottom: 1px solid rgb(0 0 0 / 20%);
}
.wd-sl-goalstop h5{
  font-size: 22px;
  margin-bottom: 0;
  font-family: 'Metropolis Bold';
}
.wd-sl-goalmiddle a {
    font-size: 13px;
    color: #8D8D8D;
    margin-right: 10px;
    font-family: 'Metropolis Semi Bold';
}
.wd-sl-goalmiddle a:last-child{
  margin-right: 0;
}
.wd-sl-goalmiddle {
    padding: 15px 20px 10px;
    text-align: right;
}
.wd-sl-goalbottom ul{
  padding:20px 20px;
  margin-bottom: 0;
  list-style: none;
}
.wd-sl-goalbottom ul li span{
  font-family: 'Metropolis Semi Bold';
  font-size: 16px;
  color: #FFFFFF;
}
.wd-sl-goalbottom ul li{
  box-shadow: 0px 6px 29px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 14px 20px;
  margin-bottom: 15px;
  display: flex;
    align-items: center;
    justify-content: space-between;
}
.wd-sl-goalbottom ul li:last-child{margin-bottom: 0;}
.wd-sl-goalbottom .color1{
  background: #BE82BC;
}
.wd-sl-goalbottom .color2{
  background: #F1D227;
}
.wd-sl-goalbottom .color3{
  background: #CBCBCB;
}
.wd-sl-goalbottom .color4{
  background: #87C1DA;
}
.wd-sl-goalbottom .color5{
  background: #71C096;
}
.wd-sl-goalbottom .color6{
  background: #9998D9;
}
.wd-sl-historyown {
    background: #FFFFFF;
    box-shadow: 8px 8px 30px rgb(0 0 0 / 10%);
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    margin: 20px 20px 10px;
}
.wd-sl-transparent .wd-sl-goalstop .tab-head {
    background: transparent;
    padding: 0;
}
.wd-sl-transparent .wd-sl-goalstop .tab-head .nav-tabs li a.active, .tab-head .nav-tabs li a:hover {
    color: #376267;
    background: transparent;
}
.wd-sl-transparent .wd-sl-goalstop .tab-head .nav-tabs li a {padding: 10px 10px;color: #B1A6A6;}
.wd-sl-histmiddle h4 {
    color: #2E5B60;
    font-size: 26px;
    display: block;
    border-bottom: 1px solid rgb(0 0 0 / 10%);
    margin-bottom: 3px;
}
.wd-sl-histmiddle span {
    font-size: 14px;
    color: #777777;
}
.wd-sl-historyown h6 {
    font-size: 18px;
    color: #777777;
}
.wd-sl-goalmiddle.profile-btn a{
  color: #FFFFFF;
    text-transform: uppercase;
    font-size: 18px;
    padding: 10px 30px 8px;
    width: auto;
    height: auto;
    line-height: normal;
}
.edit-access a{
  margin-right: 20px;
}
.edit-access a:last-child{
  margin-right: 0px;
}
.receipe-main-slider .slick-slide {padding: 0px 30px;opacity: 0.5;position: relative;}
.receipe-main-slider .slick-slide a.slid-video-popup-btn{position:absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);}
.receipe-main-slider .slick-slide img{width: 100%;}
.receipe-main-slider .slick-slide.slick-active.slick-center{opacity: 1;}

/*=========popup-dashborad==========*/
.goal-model .modal-content {
    border: 0;
    background: #FFFFFF;
    box-shadow: 0px 4px 37px rgba(0, 0, 0, 0.25);
    border-radius: 25px;
    width: 570px;
}

.goal-model .modal-content .modal-header {
    background: #35666B;
    border-radius: 20px 20px 0 0;
    justify-content: center;
    padding: 15px 30px;
    width: 570px;
}

.goal-model .modal-content .modal-header .modal-title {
    font-size: 24px;
    color: #fff;
    text-transform: uppercase;
    font-family: 'Metropolis Semi Bold';
}

.goal-model .modal-body {
    padding: 30px 30px 30px 30px;
    width: 570px;
}
.goal-model .form-row {
    margin: 0 0 20px 0;
    flex-direction: column;
}
.goal-model form .wd-md-tog-btn{
    width: 95px;
    height: 45px;
    border: 0;
    outline: none;
    background: #F5F5F5;
    border-radius: 10px;
    padding:7px;
    margin-top: 5px;
    margin-left: 10px;
}
.goal-model form .wd-md-tog-btn .btn-group.btn-toggle{
  width: 100%;
}
.goal-model form .wd-md-tog-btn a.wd-btn-wei.btn-primary{
  background: #65A6AE;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 11px;
  border: none;
  line-height: 24px;
}
.goal-model form .wd-md-wit-right img{
  width: 100%;
}
.goal-model form .wd-md-wit-right .wd-sel-photo{
    background: #65A6AE;
    border-radius: 11px;
    position: relative;
    margin-top: 18px;
    padding: 10px;
    font-size: 14px;
    color: #ffffff;
    text-align: center;
}
.goal-model form .wd-md-wit-right .wd-sel-photo input{
    opacity: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
}
.goal-model .form-row label {
    font-size: 15px;
    color: rgb(84 84 84 / 60%);
    margin: 0 0 5px 0;
    letter-spacing: 0.5px;
}

.goal-model .form-row input{
    width: 130px; 
    height: 45px;
    border: 0;
    outline: none;
    background: #F5F5F5;
    border-radius: 10px;
    padding: 0 15px;
    color: #545454;
}
.goal-model .form-row textarea {
    resize: none;
    height: 100px;
    padding: 15px;
}
.goal-model .form-row.action {
    margin: 0;
    flex-direction: row;
    justify-content: center;
}
.goal-model .form-row.action button {
    background: #504e4e;
    border: 0;
    height: 45px;
    outline: none;
    width: 150px;
    border-radius: 30px;
    margin: 0 10px;
    color: #fff;
    letter-spacing: 1px;
}
.goal-model .form-row.action button:hover {
    background: #65A6AE;
}
.goal-model .form-row.action button.save-btn {
    background: #65A6AE;
}
.goal-model .form-row.action button.save-btn:hover {
    background: #504e4e;
}
.goal-model .form-row.action button.wd-delete{
  background: #F47272;
}
.goal-model .form-row.action button.wd-delete:hover{
  background: #504e4e;
}
.goal-model .form-row.date-row input.wd-goal-m-date{
  width: 194px;
}
.add-goal-model .modal-dialog {max-width: 650px;}

.add-goal-model .modal-content {
    border: 0;
    background: #FFFFFF;
    box-shadow: 0px 4px 37px rgba(0, 0, 0, 0.25);
    border-radius: 25px;
}

.add-goal-model .modal-content .modal-header {
    background: #35666B;
    border-radius: 20px 20px 0 0;
    justify-content: center;
    padding: 15px 30px;
}

.add-goal-model .modal-content .modal-header .modal-title {
    font-size: 24px;
    color: #fff;
    text-transform: uppercase;
    font-family: 'Metropolis Semi Bold';
}

.add-goal-model .modal-body {
    padding: 30px 30px 30px 30px;
}

.add-goal-model .form-row {
    margin: 0 0 20px 0;
    flex-direction: column;
}

.add-goal-model .form-row label {
    font-size: 15px;
    color: rgb(84 84 84 / 60%);
    margin: 0 0 5px 0;
    letter-spacing: 0.5px;
}

.add-goal-model .form-row input{
    height: 45px;
    border: 0;
    outline: none;
    background: #F5F5F5;
    border-radius: 10px;
    padding: 0 15px;
    color: #545454;
}
.add-goal-model .form-row.action {
    margin: 0;
    flex-direction: row;
    justify-content: center;
}
.add-goal-model .form-row.action button {
    background: #504e4e;
    border: 0;
    height: 45px;
    outline: none;
    width: 150px;
    border-radius: 30px;
    margin: 0 10px;
    color: #fff;
    letter-spacing: 1px;
}
.add-goal-model .form-row.action button:hover {
    background: #65A6AE;
}
.add-goal-model .form-row.action button.save-btn {
    background: #65A6AE;
}
.add-goal-model .form-row.action button.save-btn:hover {
    background: #504e4e;
}
.add-goal-model .form-row.date-row input {
    max-width: 50%;
}
.checkbox-group-blocks-label-wrap ul {
  padding: 0px;
  margin: 0px;
  display: flex;
  list-style: none;
  justify-content: space-between;
}
.checkbox-group-blocks-label-wrap {
        width: 75%;
       margin: 0 auto 30px;
}
   .checkbox-group-blocks-label-wrap li {
      position: relative;
      display: inline-block;
      height: 2em;
      width: 100%;
      max-width: 3em;
      line-height: 2em;      
      color: black;
      text-align: center;
      }
    .checkbox-group-blocks-label-wrap  label {
        position: absolute;
        left: 0;
        width: 100%;
      }
      
    .checkbox-group-blocks-label-wrap  .label-text {
        background-color: whitesmoke;
        position: absolute;
        left: 0;
        width: 100%;
        border: 1px solid black;
        border-right: none;        
        user-select: none;
        transition: all 0.1s ease-in-out;
      }
      
    
        .checkbox-group-blocks-label-wrap .label-text {
          border-radius: 5px 0px 0px 5px;
        }
     
        .checkbox-group-blocks-label-wrap .label-text {
          border-right: 1px solid black;
          border-radius: 0px 5px 5px 0px;
        }
      
      .checkbox-group-blocks-label-wrap input[type="checkbox"] {
        //display: none;    
      }
      .checkbox-group-blocks-label-wrap ul li .label-text {
          background: #C4C4C4;
          border-radius: 50%;
          position: absolute;
          left: 0;
          padding: 7px 0;
          width: 100%;
          border: none;
          border-right: none;
          user-select: none;
          transition: all 0.1s ease-in-out;
      }
      .checkbox-group-blocks-label-wrap ul li input[type=checkbox]:checked + .label-text {
          background: #65A6AE;
          color: white;
          animation: push 0.2s 1 ease-out;
      }
        
@keyframes push {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}

/*=========popup-dashborad==========*/
/*===========clender-model-css=========*/
.datepicker {
  padding: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  direction: ltr;
  /*.dow {
    border-top: 1px solid #ddd !important;
  }*/
}
.datepicker-inline {
  width: 220px;
}
.datepicker.datepicker-rtl {
  direction: rtl;
}
.datepicker.datepicker-rtl table tr td span {
  float: right;
}
.datepicker-dropdown {
  top: 0;
  left: 0;
}
.datepicker-dropdown:before {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #ccc;
  border-top: 0;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  position: absolute;
}
.datepicker-dropdown:after {
  content: '';
  display: inline-block;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #ffffff;
  border-top: 0;
  position: absolute;
}
.datepicker-dropdown.datepicker-orient-left:before {
  left: 6px;
}
.datepicker-dropdown.datepicker-orient-left:after {
  left: 7px;
}
.datepicker-dropdown.datepicker-orient-right:before {
  right: 6px;
}
.datepicker-dropdown.datepicker-orient-right:after {
  right: 7px;
}
.datepicker-dropdown.datepicker-orient-top:before {
  top: -7px;
}
.datepicker-dropdown.datepicker-orient-top:after {
  top: -6px;
}
.datepicker-dropdown.datepicker-orient-bottom:before {
  bottom: -7px;
  border-bottom: 0;
  border-top: 7px solid #999;
}
.datepicker-dropdown.datepicker-orient-bottom:after {
  bottom: -6px;
  border-bottom: 0;
  border-top: 6px solid #ffffff;
}
.datepicker > div {
  display: none;
}
.datepicker.days div.datepicker-days {
  display: block;
}
.datepicker.months div.datepicker-months {
  display: block;
}
.datepicker.years div.datepicker-years {
  display: block;
}
.datepicker table {
  margin: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.datepicker td,
.datepicker th {
  text-align: center;
  width: 20px;
  height: 40px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border: none;

  font-size: 13px;
  font-weight: 700;
}
.table-striped .datepicker table tr td,
.table-striped .datepicker table tr th {
  background-color: transparent;
}
.datepicker table tr td.day:hover,
.datepicker table tr td.day.focused {
  background: #eeeeee;
  cursor: pointer;
}
.datepicker table tr td.old,
.datepicker table tr td.new {
  color: #999999;
}
.datepicker table tr td.disabled,
.datepicker table tr td.disabled:hover {
  background: none;
  color: #999999;
  cursor: default;
}
.datepicker table tr td.today,
.datepicker table tr td.today:hover,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover {
  background-color: rgb(80 143 150 / 50%);

  color: #000 !important;
}
.datepicker table tr td.today:hover,
.datepicker table tr td.today:hover:hover,
.datepicker table tr td.today.disabled:hover,
.datepicker table tr td.today.disabled:hover:hover,
.datepicker table tr td.today:active,
.datepicker table tr td.today:hover:active,
.datepicker table tr td.today.disabled:active,
.datepicker table tr td.today.disabled:hover:active,
.datepicker table tr td.today.active,
.datepicker table tr td.today:hover.active,
.datepicker table tr td.today.disabled.active,
.datepicker table tr td.today.disabled:hover.active,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today:hover.disabled,
.datepicker table tr td.today.disabled.disabled,
.datepicker table tr td.today.disabled:hover.disabled,
.datepicker table tr td.today[disabled],
.datepicker table tr td.today:hover[disabled],
.datepicker table tr td.today.disabled[disabled],
.datepicker table tr td.today.disabled:hover[disabled] {
  background-color: #508F96;
}
.datepicker table tr td.today:active,
.datepicker table tr td.today:hover:active,
.datepicker table tr td.today.disabled:active,
.datepicker table tr td.today.disabled:hover:active,
.datepicker table tr td.today.active,
.datepicker table tr td.today:hover.active,
.datepicker table tr td.today.disabled.active,
.datepicker table tr td.today.disabled:hover.active {
  background-color: #fbf069 \9;
}
.datepicker table tr td.today:hover:hover {
  color: #000;
}
.datepicker table tr td.today.active:hover {
  color: #fff;
}
.datepicker table tr td.range,
.datepicker table tr td.range:hover,
.datepicker table tr td.range.disabled,
.datepicker table tr td.range.disabled:hover {
  background: #eeeeee;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.datepicker table tr td.range.today,
.datepicker table tr td.range.today:hover,
.datepicker table tr td.range.today.disabled,
.datepicker table tr td.range.today.disabled:hover {
  background-color: #508F96;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.datepicker table tr td.range.today:hover,
.datepicker table tr td.range.today:hover:hover,
.datepicker table tr td.range.today.disabled:hover,
.datepicker table tr td.range.today.disabled:hover:hover,
.datepicker table tr td.range.today:active,
.datepicker table tr td.range.today:hover:active,
.datepicker table tr td.range.today.disabled:active,
.datepicker table tr td.range.today.disabled:hover:active,
.datepicker table tr td.range.today.active,
.datepicker table tr td.range.today:hover.active,
.datepicker table tr td.range.today.disabled.active,
.datepicker table tr td.range.today.disabled:hover.active,
.datepicker table tr td.range.today.disabled,
.datepicker table tr td.range.today:hover.disabled,
.datepicker table tr td.range.today.disabled.disabled,
.datepicker table tr td.range.today.disabled:hover.disabled,
.datepicker table tr td.range.today[disabled],
.datepicker table tr td.range.today:hover[disabled],
.datepicker table tr td.range.today.disabled[disabled],
.datepicker table tr td.range.today.disabled:hover[disabled] {
  background-color: #f3e97a;
}
.datepicker table tr td.range.today:active,
.datepicker table tr td.range.today:hover:active,
.datepicker table tr td.range.today.disabled:active,
.datepicker table tr td.range.today.disabled:hover:active,
.datepicker table tr td.range.today.active,
.datepicker table tr td.range.today:hover.active,
.datepicker table tr td.range.today.disabled.active,
.datepicker table tr td.range.today.disabled:hover.active {
  background-color: #efe24b \9;
}
.datepicker table tr td.selected,
.datepicker table tr td.selected:hover,
.datepicker table tr td.selected.disabled,
.datepicker table tr td.selected.disabled:hover {
  background-color: #508F96;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.datepicker table tr td.selected:hover,
.datepicker table tr td.selected:hover:hover,
.datepicker table tr td.selected.disabled:hover,
.datepicker table tr td.selected.disabled:hover:hover,
.datepicker table tr td.selected:active,
.datepicker table tr td.selected:hover:active,
.datepicker table tr td.selected.disabled:active,
.datepicker table tr td.selected.disabled:hover:active,
.datepicker table tr td.selected.active,
.datepicker table tr td.selected:hover.active,
.datepicker table tr td.selected.disabled.active,
.datepicker table tr td.selected.disabled:hover.active,
.datepicker table tr td.selected.disabled,
.datepicker table tr td.selected:hover.disabled,
.datepicker table tr td.selected.disabled.disabled,
.datepicker table tr td.selected.disabled:hover.disabled,
.datepicker table tr td.selected[disabled],
.datepicker table tr td.selected:hover[disabled],
.datepicker table tr td.selected.disabled[disabled],
.datepicker table tr td.selected.disabled:hover[disabled] {
  background-color: #808080;
}
.datepicker table tr td.selected:active,
.datepicker table tr td.selected:hover:active,
.datepicker table tr td.selected.disabled:active,
.datepicker table tr td.selected.disabled:hover:active,
.datepicker table tr td.selected.active,
.datepicker table tr td.selected:hover.active,
.datepicker table tr td.selected.disabled.active,
.datepicker table tr td.selected.disabled:hover.active {
  background-color: #666666 \9;
}

.datepicker table tr td.active:hover,
.datepicker table tr td.active:hover:hover,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active.disabled:hover:hover,
.datepicker table tr td.active:active,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:hover.active,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active:hover.disabled,
.datepicker table tr td.active.disabled.disabled,
.datepicker table tr td.active.disabled:hover.disabled,
.datepicker table tr td.active[disabled],
.datepicker table tr td.active:hover[disabled],
.datepicker table tr td.active.disabled[disabled],
.datepicker table tr td.active.disabled:hover[disabled] {
  background-color: #508F96;

  color: #fff !important;
}
.datepicker table tr td.active:active,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:hover.active {
  background-color: #003399 \9;
}
.datepicker table tr td span {
  display: block;
  width: 23%;
  height: 54px;
  line-height: 54px;
  float: left;
  margin: 1%;
  cursor: pointer;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.datepicker table tr td span:hover {
  background: #eeeeee;
}
.datepicker table tr td span.disabled,
.datepicker table tr td span.disabled:hover {
  background: none;
  color: #999999;
  cursor: default;
}
.datepicker table tr td span.active,
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active.disabled:hover {
  background-color: #508F96;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active:hover:hover,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active.disabled:hover:hover,
.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:hover.active,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active:hover.disabled,
.datepicker table tr td span.active.disabled.disabled,
.datepicker table tr td span.active.disabled:hover.disabled,
.datepicker table tr td span.active[disabled],
.datepicker table tr td span.active:hover[disabled],
.datepicker table tr td span.active.disabled[disabled],
.datepicker table tr td span.active.disabled:hover[disabled] {
  background-color:#508F96;
}
.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:hover.active {
  background-color: #003399 \9;
}
.datepicker table tr td span.old,
.datepicker table tr td span.new {
  color: #999999;
}
.datepicker th.datepicker-switch {
  width: 145px;
}
.datepicker thead tr:first-child th,
.datepicker tfoot tr th {
  cursor: pointer;
  font-size: 21px;
  color: #508F96;
}
.datepicker thead tr:first-child th:hover,
.datepicker tfoot tr th:hover {
  background: #eeeeee;
}
.datepicker .cw {
  font-size: 10px;
  width: 12px;
  padding: 0 2px 0 5px;
  vertical-align: middle;
}
.datepicker thead tr:first-child th.cw {
  cursor: default;
  background-color: transparent;
}
.input-append.date .add-on i,
.input-prepend.date .add-on i {
  cursor: pointer;
  width: 16px;
  height: 16px;
}
.input-daterange input {
  text-align: center;
}
.input-daterange input:first-child {
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
}
.input-daterange input:last-child {
  -webkit-border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
}
.input-daterange .add-on {
  display: inline-block;
  width: auto;
  min-width: 16px;
  height: 20px;
  padding: 4px 5px;
  font-weight: normal;
  line-height: 20px;
  text-align: center;
  text-shadow: 0 1px 0 #ffffff;
  vertical-align: middle;
  background-color: #eeeeee;
  border: 1px solid #ccc;
  margin-left: -5px;
  margin-right: -5px;
}
.datepicker.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  float: left;
  display: none;
  min-width: 160px;
  list-style: none;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  *border-right-width: 2px;
  *border-bottom-width: 2px;
  color: #333333;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 13px;
  line-height: 20px;
}
.datepicker.dropdown-menu th,
.datepicker.datepicker-inline th,
.datepicker.dropdown-menu td,
.datepicker.datepicker-inline td {
  padding: 4px 5px;
}

.datepicker .dow {

  border-bottom: 1px solid #e7e7e7;

  color: #9b8079;
  font-size: 13px;
}

.datepicker-months .prev,
.datapicker-months .next,
.datepicker-years .prev,
.datepicker-years .next {
  width: 58px;
}

.datepicker-days tbody td:nth-child(6),
.datepicker-days tbody td:nth-child(7) {
  color: #D44;
}

/* @import url("normalize.css");
@import url("datepicker.css"); */

.charset {
  box-shadow: 1px 1px 10px #000;
}

.app {
  width: 969px; height: 580px;
  margin: 100px auto;
}
.app:after {
  content: "";
  display: table;
  clear: both;
}
  .app__side {
    background: #333;
    width: 35%; height: 100%;
    padding: 40px;
    float: left;

    color: #508F96;
  }
  .app__main {
    background: #fbf9fa;
    width: 65%; height: 100%;
    float: left;
    padding: 40px;
  }

  .calendar .datepicker {
    width: 100%;
  }
    .calendar .datepicker table {
      width: 100%;
    }
/*===========clender-model-css=========*/
.wd-sl-transparent .fade:not(.show){
  display: none;
}
path.highcharts-plot-band {
    display: none;
}
.highcharts-area {
    fill-opacity: unset;
    fill: #DBE9E7;
}
.highcharts-tracker-line {
    stroke: rgb(255 255 255);
    stroke-width: 6px;
}
.highcharts-color-0{
  stroke: #FFFFFF;
}
g.highcharts-legend-item.highcharts-areaspline-series.highcharts-color-0.highcharts-series-0, button.highcharts-a11y-proxy-button.highcharts-no-tooltip{
    display: none;
}
#container-new {
    margin: 0 20px;
}
.wd-sl-calpop{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 30px 15px;
}
.wd-sl-calpop h5{
  font-size: 18px;
  color: #3F7379;
  margin: 0;
}
/*============alert-model=============*/
#goal-alert .modal-dialog{
  width: 500px;
}
#goal-alert .modal-dialog .modal-header{
  align-items: center;
  position: relative;
}
#goal-alert .modal-dialog .modal-header button.close{
  margin: 0;
  padding: 0;
  position: absolute;
  right: 14px;
  opacity: 1;
}
#goal-alert .modal-dialog .modal-header button.close i{
  color: #ffffff;
}
#goal-alert .wd-wei-blog .wd-wei{
  background: #F47272;
  box-shadow: 0px 17px 18px 1px rgba(244, 114, 114, 0.21);
  padding: 10px;
  border-radius: 50%;
  width: 110px;
  height: 110px;
  text-align: center;
  margin:0 auto 15px;
}
#goal-alert .wd-wei-blog .wd-wei span{
  border: 1px solid #ffffff;
  border-radius: 50%;
  padding: 36px 0;
}
#goal-alert .wd-wei-blog .wd-wei img{
  padding: 21px;
}
#goal-alert .wd-wei-blog .wd-wei-inner h4{
  font-family: Metropolis;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  color: #F47272;
  text-align: center;
}
#goal-alert .wd-wei-blog .wd-wei-inner h4 span{
  color: #000000;
}
#goal-alert .wd-wei-blog .wd-wei-inner p{
  font-family: Metropolis;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  color: #F47272;
  text-align: center;
}
#goal-alert .wd-wei-blog .wd-wei-rec-inner .wd-wei-rec{
  background: #65A6AE;
  box-shadow: 0px 17px 18px 1px rgba(101, 166, 174, 0.21);
  padding: 10px;
  border-radius: 50%;
  width: 110px;
  height: 110px;
  text-align: center;
  margin:0 auto 15px;
}
#goal-alert .wd-wei-blog .wd-wei-rec-inner .wd-wei-rec span{
  border: 1px solid #ffffff;
  border-radius: 50%;
  padding: 36px 0;
}
#goal-alert .wd-wei-blog .wd-wei-rec-inner .wd-wei-rec img{
  padding: 21px;
}
#goal-alert .wd-wei-blog .wd-wei-rec-inner h4{
  font-family: Metropolis;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  color: #009247;
  text-align: center;
}
#goal-alert .wd-wei-blog .wd-wei-rec-inner h4 span{
  color: #000000;
}
#goal-alert .wd-wei-blog .wd-wei-rec-inner p{
  font-family: Metropolis;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  color: #009247;
  opacity: 0.8;
}
/*============alert-model=============*/
.Weekly-blog .Weekly-box img{
  margin-top: -40px;
    width: 60px;
    height: 60px;
    object-fit: contain;
}
.wd-sl-recmain .tab-pane.show, .wd-sl-dashtabs .tab-pane.show{
    max-width: 100%;
    margin: 0 auto;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}
#history, #chart {
    columns: unset !important;
    column-gap: unset !important;
}
.ribbon3 {
  padding: 5px 15px;
  position: absolute;
  left: -6px;
  top: 20px;
  background: #2FD2C9;
  border-radius: 0px 50px 50px 0;
  font-size: 12px !important;
  color: #FFFFFF !important;
  font-family: 'Metropolis Semi Bold';
  z-index: 1;
}
.ribbon3:before, .ribbon3:after {
  content: "";
  position: absolute;
}
.ribbon3:before {
  height: 0;
  width: 0;
  top: -9px;
  left: 0px;
  border-bottom: 9px solid #83920C;
  border-left: 9px solid transparent;
}
.payment-page .ds-rightbar{
  padding-bottom: 75px;
}